@import '../styles/variables';
.container {
  margin-top: 2 * $spacer;
  margin-bottom: 2 * $spacer;
  padding: $spacer;
  border-width: 1px;
  border-color: $light;
  border-style: solid;
  border-radius: 5px;
  
}
.info {
  margin-bottom: $spacer;
}
input,
select,
textarea {
  margin-bottom: 0.5 * $spacer;
}
input,
[type="number"] {
  width: 20%;
}
.actionContainer{
  display: flex;
  justify-content: space-between
}

.submit{
  min-width: 200px;
}
tr{
  cursor: pointer;
}
.mediaImage{
  width: 100%;
  max-height: 360px;
  object-fit: contain;
  
}
.storyTypeContainer{
  display: flex,

}
.checkbox{
  margin: 0.5 * $spacer;
}
.checkbox input{
  width: 20px !important;
  margin:  0 !important;
}
.checkbox label{
  margin: 0 0.5*$spacer;
}
.tooltip {
  position: relative;
  display: inline-block;
  margin: auto 0;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

