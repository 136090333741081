$light: rgb(120, 35, 15, 0.6);
$dark: rgb(120, 35, 15);
$secondary: rgb(5, 35, 25);
$body-color: rgb(5, 35, 25);
$body-bg: #fffefa;
$font-size-base: 1.1rem;


@import "~bootstrap-v4-rtl/scss/functions";
@import "~bootstrap-v4-rtl/scss/variables";
@import "~bootstrap-v4-rtl/scss/mixins/_breakpoints";
